import { useEffect, useRef } from 'react';
import React from 'react';
import { Box, Avatar, Typography, Card, styled, Divider } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { formatDistance, format, isToday, isYesterday } from 'date-fns';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import _ from 'lodash';
import { EMessageType, IAccount } from '../../Interfaces/generalInterfaces';
import { formatPrice } from '../../utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      .MuiDivider-wrapper {
        
        border-radius: 10px;
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: black};
      }
`
);

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: #FEEEE8;
      color: #333;
      padding: ${theme.spacing(2)};
      border-radius: 20px;
      border-top-right-radius: 10px;
      max-width: 380px;
      display: inline-flex;
`
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
      background: rgba(34, 51, 84, 0.1) ;
      color: #333;
      padding: ${theme.spacing(2)};
      border-radius: 20px;
      border-top-left-radius: 10px;
      max-width: 380px;
      display: inline-flex;
`
);
interface ChatContentProps {
  messages: any;
  messageReactions: any;
  account: IAccount;
}

const ChatContent: React.FC<ChatContentProps> = React.memo(
  ({ messages, messageReactions, account }) => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const chatContainerRef = useRef(null);
    const scrollToBottom = () => {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      chatContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
      scrollToBottom();
    }, [messages]);

    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      const formattedDate = isToday(date)
        ? t('Today')
        : isYesterday(date)
        ? t('Yesterday')
        : format(date, 'MMMM dd yyyy');
      return formattedDate;
    };

    const formatMessageDate = (timestamp) => {
      return formatDistance(new Date(timestamp), new Date(), {
        addSuffix: true,
      });
    };

    const renderChatMessages = () => {
      const messageReactionsChatOnly = _.filter(messageReactions, (v) => {
        return v.messageType !== EMessageType.CONFIRMATION;
      });
      const mergedMessages = _.concat(
        _.map(messages, (v) => {
          return {
            ...v,
            sender: 'GUEST',
          };
        }),
        _.map(messageReactionsChatOnly, (v) => {
          return {
            ...v,
            id: 'account-' + v.id,
            sender: 'ACCOUNT',
          };
        })
      );
      const sortedMessages = _.sortBy(
        mergedMessages,
        (message) => message.timestamp
      );
      const groupedMessages = _.groupBy(sortedMessages, (message) =>
        formatDate(message.timestamp)
      );

      return Object.entries(groupedMessages).map(([date, messages], index) => (
        <Box key={index}>
          <div className="my-4">
            <DividerWrapper>{date}</DividerWrapper>
          </div>
          {messages.map((message, index) =>
            renderMessage(
              message,
              message.sender,
              index === messages.length - 1
            )
          )}
        </Box>
      ));
    };

    const renderOrder = (message: string) => {
      const order = JSON.parse(message);
      const totalPrice = () => {
        if (account.is_inclusive) {
          return parseFloat(order.net_total + order.tax_amount);
        } else {
          return parseFloat(order.net_total);
        }
      };
      return (
        <div className="block  text-left">
          <p className="mb-4">{t('Order Summary')}</p>
          {order.orderItems.map((item, key) => (
            <div>
              <p>
                <b>{item.quantity}</b> x {item.products.name} -{' '}
                {formatPrice(item.quantity * item.price)}{' '}
                {item.remark && (
                  <span className="text-xs font-bold italic text-red-500">
                    {` * ${item.remark}`}
                  </span>
                )}
              </p>
            </div>
          ))}
          <p className="mt-4">{t('Subtotal')}: {formatPrice(order.sub_total)} </p>
          {order.discount && <p> {t('Discount')} : {formatPrice(order.discount)} </p>}
          {!account?.is_inclusive && order.tax_amount && (
            <p>{t('Tax')}: {formatPrice(order.tax_amount)} </p>
          )}
          <p>{t('Total')}: {formatPrice(totalPrice())} </p>
        </div>
      );
    };

    const renderMessage = (message, sender, index) => {
      const isGuest = sender === 'GUEST';
      return (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent={isGuest ? 'flex-end' : 'flex-start'}
          mt={2}
          key={message.id}
        >
          {isGuest ? (
            <>
              <Box
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                justifyContent="flex-end"
                mr={2}
              >
                {message.messageType === EMessageType.BUZZ && (
                  <div className="relative rounded-xl bg-7m-pink-100 text-black border align-middle text-left cursor-pointer hover:bg-7m-bg-main hover:text-white">
                    <div className="p-2">
                      <p className="text-xs">{t('Request')}:</p>
                      <p className="text-md">
                        {message.originalMessage ?? message.message}
                      </p>
                    </div>
                    {messageReactions[message.id] && (
                      <div className="p-2 text-green-900 border-t rounded-b-md  text-xs">
                        <CheckCircleIcon fontSize="small" color="success" />
                        {t('Confirmed')}
                      </div>
                    )}
                  </div>
                )}
                {message.messageType === EMessageType.CHAT && (
                  <CardWrapperPrimary sx={{ mt: 2 }}>
                    {message.originalMessage ?? message.message}
                    
                  </CardWrapperPrimary>
                )}
                {message.messageType === EMessageType.IMAGE && (
                  <CardWrapperPrimary sx={{ mt: 2 }}>
                    <img src={message.message} alt="" />
                  </CardWrapperPrimary>
                )}
                {message.messageType === EMessageType.ORDER && (
                  <CardWrapperPrimary sx={{ mt: 2 }}>
                    {renderOrder(message.message)}
                    {messageReactions[message.id] && (
                      <div className="  text-green-900 rounded-b-md  text-xs">
                        <CheckCircleIcon fontSize="small" color="success" />
                        {t('Confirmed')}
                      </div>
                    )}
                  </CardWrapperPrimary>
                )}
                {isToday(message.timestamp) && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      pt: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ScheduleTwoToneIcon
                      sx={{
                        mr: 0.5,
                      }}
                      fontSize="small"
                    />
                    {formatMessageDate(message.timestamp)}
                  </Typography>
                )}

                {!isToday(message.timestamp) && index && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      pt: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ScheduleTwoToneIcon
                      sx={{
                        mr: 0.5,
                      }}
                      fontSize="small"
                    />
                    {formatMessageDate(message.timestamp)}
                  </Typography>
                )}
              </Box>
            </>
          ) : (
            <>
              <Avatar
                variant="rounded"
                sx={{ width: 50, height: 50 }}
                alt="Sooner"
                src="/sooner.jpg"
              />
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                justifyContent="flex-start"
                ml={2}
              >
                {message.messageType === EMessageType.CHAT && (
                  <CardWrapperSecondary className="bg-7m-gray-100">
                    {message.message}
                  </CardWrapperSecondary>
                )}
                {message.messageType === EMessageType.IMAGE && (
                  <CardWrapperSecondary>
                    <img src={message.message} alt="" />
                  </CardWrapperSecondary>
                )}
                {isToday(message.timestamp) && (
                  <Typography
                    variant="subtitle1"
                    sx={{ pt: 1, display: 'flex', alignItems: 'center' }}
                  >
                    <ScheduleTwoToneIcon sx={{ mr: 0.5 }} fontSize="small" />
                    {formatMessageDate(message.timestamp)}
                  </Typography>
                )}

                {!isToday(message.timestamp) && index && (
                  <Typography
                    variant="subtitle1"
                    sx={{ pt: 1, display: 'flex', alignItems: 'center' }}
                  >
                    <ScheduleTwoToneIcon sx={{ mr: 0.5 }} fontSize="small" />
                    {formatMessageDate(message.timestamp)}
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
      );
    };

    return (
      <Box
        p={3}
        ref={chatContainerRef}
        style={{ overflowY: 'auto', maxHeight: '100vh', paddingBottom: '5rem' }}
      >
        {renderChatMessages()}
      </Box>
    );
  }
);

export default ChatContent;
