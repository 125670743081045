import { Step, StepLabel, Stepper } from '@mui/material';
import React, { useState } from 'react';
import {
  EScreenMode,
  IAccount,
  ICartItem,
} from '../../Interfaces/generalInterfaces';
import { HeaderNav } from './HeaderNav';
import { formatPrice } from '../../utils';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
export interface OrderSummaryProp {
  carts: Array<ICartItem>;
  onSubmitOrder: () => void;
  onItemRemoved: (id: string) => void;
  onDismiss: () => void;
  account?: IAccount;
}
export const OrderSummary: React.FC<OrderSummaryProp> = ({
  carts,
  onSubmitOrder,
  onItemRemoved,
  onDismiss,
  account,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();

  const [screenMode, setScreenMode] = useState<EScreenMode>(
    EScreenMode.DISMISS
  );

  const handleStScreenMode = (screenMode: EScreenMode) => {
    setScreenMode(screenMode);
  };
  const steps = ['Menu', 'Add to Cart', 'Order'];

  const totalPrice = carts.reduce((total, item) => {
    return total + item.product.price * item.value;
  }, 0);

  const discount = () => {
    return 0;
  };
  const tax = () => {
    if (account.is_inclusive === false && account.tax_rate > 0) {
      return totalPrice * (account.tax_rate / 100);
    } else {
      return 0;
    }
  };
  const priceAfterTax = () => {
    return totalPrice + tax();
  };

  return (
    <div className="overflow-scroll fixed bg-white top-0 z-20 w-full h-full">
      <HeaderNav
        setScreenMode={handleStScreenMode}
        screenMode={screenMode}
        onDismiss={onDismiss}
        title={t('Order Summary')}
      />
      <div className="mt-6">
        <Stepper className="bg-red" activeStep={2} alternativeLabel>
          {steps.map((label) => (
            <Step
              key={label}
              sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: '#FE571D', // circle color (COMPLETED)
                },

                '& .MuiStepLabel-root .Mui-active': {
                  color: 'grey.500', // circle color (ACTIVE)
                },

                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
              }}
            >
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="mt-4 mb-16 text-left">
        <h1 className="text-2xl font-bold mx-5 mb-5">{t('Order Summary')}</h1>
        {carts.map((item, key) => (
          <div
            key={key}
            className=" bg-7m-bg-gray-primary border-b border-slate-200"
          >
            <div className="flex space-x-2 items-center p-5 relative justify-between">
              <div
                className=" text-right"
                onClick={() => onItemRemoved(item.id)}
              >
                <ClearIcon fontSize="small" color="disabled" />
              </div>
              <div>
                <img
                  className="w-16 rounded-md"
                  alt="food"
                  src={item.product.image_url}
                />
              </div>
              <div>
                <p className="w-28 text-xs">{item.product.name}</p>
                <p className="text-xs">
                  {' '}
                  {formatPrice(item.product.price)} x {item.value}
                </p>
              </div>

              <p>{formatPrice(item.value * item.product.price)}</p>
            </div>
          </div>
        ))}
        <div className="p-5">
          <div className="flex justify-between mb-4">
            <p>{t('Subtotal')}</p>
            <p>{formatPrice(totalPrice)}</p>
          </div>
          {!account.is_inclusive && account.tax_rate > 0 && (
            <div className="flex justify-between mb-4">
              <p>VAT {account.tax_rate}%</p>
              <p>{formatPrice(tax())}</p>
            </div>
          )}

          <div className="flex justify-between mb-4">
            <p className="text-xl font-bold">{t('Total')} (USD)</p>
            <p className="text-xl font-bold">{formatPrice(priceAfterTax())}</p>
          </div>
        </div>
      </div>

      <div className="fixed w-full bottom-0 px-5 py-3 bg-7m-pink-100">
        <div
          onClick={onSubmitOrder}
          className=" bg-7m-bg-main rounded-2xl border border-7m-gray-100 p-4 align-middle items-center text-white text-center"
        >
          <p className="font-bold">{t('Place Order')}</p>
        </div>
      </div>
    </div>
  );
};
