import { IUser } from './User/Users';
import * as PusherTypes from 'pusher-js';
import { v4 as uuidv4 } from 'uuid';

// Interfaces
import {
  BuzzItem,
  EntityMapper,
  IHandshakeResponse,
  IMessage,
  IRestaurantInfo,
  ITable,
  IUploadResponse,
} from './Interfaces/generalInterfaces';
import { ISurveyData } from './Auth/Interfaces';
import { ETypeOfMenu } from './User/Components/NewMenu';
import { BroadCasterConfig } from './RestaurantViews/Components/NotificationSetting/Notification.interface';
import {
  AuthTokenResponsePassword,
  createClient,
  User,
} from '@supabase/supabase-js';
import { table } from 'console';
import { tab } from '@testing-library/user-event/dist/tab';

export interface INetworkResponse<T> {
  success: boolean;
  payload?: T;
  message?: string;
}

type TGetToken = () => string;
type TGetAccountId = () => string;
type TLogoutUser = () => void;
type TUpdateToken = (JWT: string) => void;

const axios = require('axios').default;
const API_URL: string = window._env_.API_URL || 'http://localhost:3006';

export default class NetworkManager {
  getToken: TGetToken;
  logoutUser: TLogoutUser;
  updateJWT: TUpdateToken;
  accountId: TGetAccountId;

  constructor(
    getToken: TGetToken,
    logoutUser: TLogoutUser,
    updateJWT: TUpdateToken,
    accountId: TGetAccountId
  ) {
    this.getToken = getToken;
    this.logoutUser = logoutUser;
    this.updateJWT = updateJWT;
    this.accountId = accountId;

    axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }
  static async translateText(
    message: string,
    targetLang: string
  ): Promise<any> {
    return await axios.post(`${API_URL}/translate`, {
      targetLang,
      message,
    });
  }
  static async detectLanguage(text: string): Promise<any> {
    return await axios.post(`${API_URL}/translate/detect`, {
      text,
    });
  }

  async toggleRestaurantLanguage(): Promise<INetworkResponse<any>> {
    const { data } = await axios.put(
      `${API_URL}/restaurants/language/toggle`,
      {},
      {
        headers: {
          Authorization: this.getAuthorization(),
        },
      }
    );
    return { success: true, payload: data };
  }
  async getRestaurantLanguage(): Promise<INetworkResponse<any>> {
    /* TODO: Fix with localize Feature */
    const data = ['en'];
    return { success: true, payload: data };
  }
  async updateRestaurantLanguage(
    restaurantLanguage: any
  ): Promise<INetworkResponse<any>> {
    try {
      const resp = await axios.put(
        `${API_URL}/restaurants/language/`,
        restaurantLanguage,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );
      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error updating language';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateBroadCastingService(
    broadcasterService: Array<BroadCasterConfig>
  ): Promise<INetworkResponse<any>> {
    try {
      const resp = await axios.put(
        `${API_URL}/restaurants/broadcaster`,
        broadcasterService,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );
      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error updating language';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  // async login(username: string, password: string): Promise<any> {
  //   try {
  //     const { data } = await axios.post(`${API_URL}/auth/login`, {
  //       username,
  //       password,
  //     });

  //     if (!data?.access_token) {
  //       const errorMessage = data.message || 'Unhandled error at login';
  //       return { success: false, payload: null, message: errorMessage };
  //     }

  //     return { success: true, payload: data.access_token };
  //   } catch (error: any) {
  //     const errorMessage = error.message || 'Unhandled error at login';
  //     console.error(error);
  //     return { success: false, payload: null, message: errorMessage };
  //   }
  // }

  async login(
    username: string,
    password: string
  ): Promise<AuthTokenResponsePassword> {
    const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
    return await supabase.auth.signInWithPassword({
      email: username,
      password: password,
    });
  }

  async getAccountInfo(user: User) {
    const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);

    const { data, error } = await supabase
      .from('profiles')
      .select(
        ` role,
                accounts (
                    id,
                    uuid,
                    name,
                    active,
                    type,
                    phone_number
                )
            `
      )
      .eq('user_id', user.id)
      .single();

    return data;
  }

  getAuthorization(): string {
    return 'Bearer ' + this.getToken();
  }

  async getProfile(userId: string) {
    try {
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const { data, error } = await supabase
        .from('profiles')
        .select(
          ` role,
                accounts (
                    id,
                    uuid,
                    name,
                    active,
                    type,
                    phone_number
                )
            `
        )
        .eq('user_id', userId)
        .single();
      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error at get profile';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getUsers(showAll: boolean = false): Promise<INetworkResponse<any>> {
    try {
      const url = showAll
        ? `${API_URL}/users?showDeleted=true`
        : `${API_URL}/users`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error getting users';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async addUser(
    username: string,
    displayName: string,
    profiles: string[],
    password: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users`,
        {
          restaurantId: '',
          username,
          password,
          displayName,
          profiles,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: data.insertedId };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error adding new user';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async deleteUser(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.delete(`${API_URL}/users/${id}`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error deleting user';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async softDeleteUser(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users/${id}/softdelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unhandled error soft-deleting user';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async undoSoftDeleteUser(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users/${id}/undosoftdelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error restoring user';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  // TODO: update input signature
  async updateUser(user: any): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.patch(
        `${API_URL}/users/${user._id}`,
        {
          ...user,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error updating user';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getTables(): Promise<INetworkResponse<any>> {
    try {
      // const accountId = this.accountId();
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const { data, error } = await supabase
        .from('resources')
        .select(
          'id,uuid, name, dynamicCode, description, channel(id, channelId)'
        )
        .eq('account_id', this.accountId())
        .eq('resource_type', 'table');

      if (error) {
        return { success: false, payload: null, message: error.message };
      }

      const response = data.map((row) => {
        const lastChannel =
          row.channel
            ?.sort((a: any, b: any) => b.id - a.id) // Sort by id in descending order
            ?.at(0) ?? null;
        return {
          tableId: row.name,
          _id: row.id,
          _uuid: row.uuid,
          channelId: lastChannel ? lastChannel.channelId : null, // Extract channelId from the last channel
          ...row,
        };
      });

      return { success: true, payload: response };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error getting tables';
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async createTable(
    tableId: string,
    description: string
  ): Promise<INetworkResponse<any>> {
    try {
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const channelId = 'private-' + uuidv4();
      const dynamicCode = uuidv4();

      //TODO: should be wrapped inside database transaction which is not supported by supabase for now.
      const insertedTable = await supabase
        .from('resources')
        .insert({
          name: tableId,
          resource_type: 'table',
          description: description,
          dynamicCode: dynamicCode,
          account_id: this.accountId(),
        })
        .select()
        .single();

      if (insertedTable.error) {
        const errorMessage =
          insertedTable.error.message || 'Unhandled error adding new table';
        return { success: false, payload: null, message: errorMessage };
      }

      const { error } = await supabase.from('channel').insert([
        {
          resource_id: insertedTable.data.id,
          events: [],
          account_id: this.accountId(),
          channelId: channelId,
        },
      ]);

      if (error) {
        const errorMessage =
          error.message || 'Unhandled error adding new table';
        return { success: false, payload: null, message: errorMessage };
      }

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error adding new table';
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async deleteTable(id: string): Promise<INetworkResponse<any>> {
    try {
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const deleteTable = await supabase
        .from('resources')
        .delete()
        .eq('id', id);
      const deleteChannel = await supabase
        .from('channel')
        .delete()
        .eq('resource_id', id);

      if (deleteTable.error || deleteChannel.error) {
        const errorMessage =
          deleteTable.error.message || 'Unhandled error adding new table';
        return { success: false, payload: null, message: errorMessage };
      }
      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error deleting table';
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async softDeleteTable(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/tables/${id}/softDelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unhandled error soft-deleting table';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async undoSoftDeleteTable(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/tables/${id}/undosoftDelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error restoring table';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateTables(table: any): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.patch(
        `${API_URL}/tables/${table._id}`,
        {
          ...table,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error updating table';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getAllPersistentMessages1(): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.get(`${API_URL}/channel`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error getting persistent messages';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getRestaurant(
    restaurantId: string
  ): Promise<INetworkResponse<IRestaurantInfo>> {
    try {
      const { data } = await axios.get(
        `${API_URL}/restaurants/${restaurantId}`,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error getting restaurant';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async setMenu(
    name?: string,
    url?: string,
    type?: string,
    seletedFile?: File
  ): Promise<INetworkResponse<any>> {
    try {
      const formData = new FormData();
      if (type === ETypeOfMenu.PDF) {
        //* NEED UPATE API to able to upload file
        if (seletedFile) {
          formData.append('file', seletedFile);
          formData.append('folderName', 'dev');
          const { data } = await axios.post(`${API_URL}/upload`, formData, {
            headers: {
              Authorization: this.getAuthorization(),
              'content-type': 'multipart/form-data',
            },
          });
          url = data.fileUrl;
        }
      }
      const { data } = await axios.post(
        `${API_URL}/restaurants/menu`,
        {
          name: name,
          url: url,
          type: type,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );
      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || 'Unexpected error saving menu';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  // static async clientUploadFile(
  //   folderName: string,
  //   seletedFile?: File,
  // ): Promise<INetworkResponse<any>> {
  //   try {
  //     const formData = new FormData();

  //     if (seletedFile) {
  //       formData.append("file", seletedFile);
  //     }
  //     formData.append("folderName", folderName);
  //     const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);

  //     // const { data, error } = await supabase
  //     //   .storage
  //     //   .getBucket(folderName);

  //     const { data } = await axios.post(`${API_URL}/upload`, formData, {
  //       headers: {
  //         Authorization: this.getAuthorization(),
  //         "content-type": "multipart/form-data",
  //       },
  //     });

  //     return {
  //       success: true,
  //       payload: data,
  //     };
  //   } catch (error: any) {
  //     const errorMessage = error.message || "Unexpected error saving menu";
  //     console.error(error);
  //     return { success: false, payload: null, message: errorMessage };
  //   }
  // }

  // Upload file using standard upload
  static async supabaseUpload(
    path,
    file
  ): Promise<INetworkResponse<IUploadResponse>> {
    try {
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const { data, error } = await supabase.storage
        .from('public_demo')
        .upload(`public/${fileName}`, file);

      if (error) throw error;
      return {
        success: true,
        payload: data as any,
        message: 'File uploaded successfully',
      };
    } catch (error) {
      const errorMessage = error.message || 'Unexpected error uploading file';
      return { success: false, payload: null, message: errorMessage };
    }
  }
  async supabaseUploadAdmin(file): Promise<INetworkResponse<IUploadResponse>> {
    try {
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const { data, error } = await supabase.storage
        .from('public_demo')
        .upload(`public/${fileName}`, file);

      if (error) throw error;
      return {
        success: true,
        payload: data as any,
        message: 'File uploaded successfully',
      };
    } catch (error) {
      const errorMessage = error.message || 'Unexpected error uploading file';
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async deleteMenu(menuId: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.delete(
        `${API_URL}/restaurants/menu/${menuId}`,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );
      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || 'Unexpected error deleting menu';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateMenu(
    menuName: string,
    menuURL: string,
    type: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.patch(
        `${API_URL}/restaurants/menu/${menuName}`,
        {
          name: menuName,
          url: menuURL,
          type: type,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || 'Unexpected error updating menu';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async clearTable(tableId: string): Promise<INetworkResponse<any>> {
    try {
      const channelId = 'private-' + uuidv4();
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const insertedChannel = await supabase
        .from('channel')
        .insert({
          resource_id: tableId,
          account_id: this.accountId(),
          channelId: channelId,
          events: [],
        })
        .select()
        .single();

      if (insertedChannel.error) {
        const errorMessage =
          insertedChannel.error.message || 'Error clearing table session';
        return { success: false, payload: null, message: errorMessage };
      }

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error deleting buzz';
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async regenreateQRCode(tableId: string): Promise<INetworkResponse<any>> {
    try {
      /* TODO : Send to Supabase */
      const { data } = await axios.post(
        `${API_URL}/tables/${tableId}/regenerate`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error at regenerate QR Code';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getRestaurants(
    showAll: boolean = false
  ): Promise<INetworkResponse<any>> {
    try {
      const url = showAll
        ? `${API_URL}/restaurants?showDeleted=true`
        : `${API_URL}/restaurants`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error getting restaurants';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async newRestaurant(
    name: string,
    owner: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants`,
        {
          name,
          owner,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error adding new restaurant';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async deleteRestaurant(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.delete(`${API_URL}/restaurants/${id}`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error deleting restaurant';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async softDeleteRestaurant(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants/${id}/softdelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error soft-deleting restaurant';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async undoSoftDeleteRestaurant(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants/${id}/undosoftdelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error restoring restaurant';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateRestaurant(
    id: string,
    name: string,
    owner: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.patch(
        `${API_URL}/restaurants/${id}`,
        {
          name,
          owner,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error updating restaurant';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async addNewClient(newClient: any): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users/newclient`,
        {
          ...newClient,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error updating restaurant';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateOwnersPassword(
    ownerName: string,
    newPassword: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users/${ownerName}/changepass`,
        {
          password: newPassword,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error updating restaurant';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateBuzz(buzzItem: Array<BuzzItem>): Promise<INetworkResponse<any>> {
    try {
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const { error } = await supabase
        .from('resources')
        .update({ dynamic_content: buzzItem })
        .eq('account_id', this.accountId());

      if (error) {
        return { success: false, payload: null, message: error.message };
      }
      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error deleting buzz';
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getBroadCasterList(): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.get(`${API_URL}/restaurants/broadcaster/`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage = error.message || 'Unhandled error deleting buzz';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getBuzzList(): Promise<INetworkResponse<BuzzItem[] | null>> {
    const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
    const { data, error } = await supabase
      .from('resources')
      .select('id, dynamic_content')
      .eq('account_id', this.accountId())
      .eq('resource_type', 'buzz')
      .limit(1)
      .single();
    return { success: true, payload: data.dynamic_content };
  }

  async updateSurveyData(
    surveyData: ISurveyData
  ): Promise<INetworkResponse<string[] | null>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants/survey/`,
        {
          ...surveyData,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unhandled error updating survey data';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateWelcomeMessage(
    newWelcomeMessage: string
  ): Promise<INetworkResponse<string[] | null>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants/welcomeMessage/`,
        {
          newWelcomeMessage,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unhandled error updating welcome message';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  static async sendMessageToBackend(
    tableId: string | undefined,
    message: IMessage,
    channel: string,
    event: string
  ): Promise<INetworkResponse<any>> {
    const finalMessage = {
      tableId,
      time_ms: message.timestamp,
      events: [
        {
          channel: channel,
          data: JSON.stringify(message),
          event: event,
          name: 'client_event',
        },
      ],
    };
    try {
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const events = null;
      const { data, error } = await supabase
        .from('channel')
        .select('events, resource_id, account_id')
        .eq('channelId', channel)
        .limit(1)
        .single();
      if (data) {
        data.events.push({
          channel: channel,
          data: message,
          event: event,
          name: 'client_event',
        });
        await supabase
          .from('channel')
          .update({
            resource_id: data.resource_id,
            events: data.events,
            channelId: channel,
            account_id: data.account_id,
          })
          .eq('channelId', channel);
      } else {
        const events = finalMessage.events;
        const response = await supabase.from('channel').insert({
          resource_id: 1,
          events: events,
          channelId: channel,
          account_id: data.account_id,
        });
        if (!response.error) {
          return {
            success: true,
            payload: events,
          };
        }
      }
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unexpected error updating restaurant';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  pusherAuthorizerStaff(channel: PusherTypes.Channel, authToken?: string) {
    return {
      authorize: (socketId: string, callback: any) => {
        fetch(`${window._env_.API_URL}/pusher/staffAuth`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: this.getAuthorization(),
          }),
          body: JSON.stringify({
            socketId: socketId,
            channelId: channel.name,
          }),
        })
          .then((res: any) => {
            if (!res.ok) {
              throw new Error(`Received ${res.statusCode} from `);
            }

            return res.json();
          })
          .then((data) => {
            callback(null, data);
          })
          .catch((err) => {
            console.error('ERR', err);

            callback(new Error(`Error calling auth endpoint: ${err}`), {
              auth: '',
            });
          });
      },
    };
  }

  pusherAuthorizerRestaurant(socketId: string, channelId: string) {
    fetch(`${window._env_.API_URL}/pusher/restaurantAuth`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        socketId: socketId,
        channelId: channelId,
      }),
    })
      .then((res: any) => {
        if (!res.ok) {
          throw new Error(`Received ${res.statusCode} from `);
        }
        return res.json();
      })
      .catch((err) => {
        console.error('ERR', err);
      });
  }

  // Static functions
  static async getPersistentMessages(
    channelId: string
  ): Promise<INetworkResponse<any>> {
    try {
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const { data, error } = await supabase
        .from('channel')
        .select('resource_id, events, account_id')
        .eq('channelId', channelId)
        .single();
      if (error) {
        const errorMessage =
          error.message || 'Unhandled error getting persistent messages';
        console.error(error);
        return { success: false, payload: null, message: errorMessage };
      }

      const groupedData = {};

      data.events.forEach((event) => {
        const eventType = event.event;
        const eventData =
          typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

        if (!groupedData[eventType]) {
          groupedData[eventType] = [];
        }

        groupedData[eventType].push({
          data: eventData,
          name: event.name,
          channel: event.channel,
          event: event.event,
        });
      });
      data.events = groupedData;

      return {
        success: true,
        payload: data || [],
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unhandled error getting persistent messages';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }
  async getAllPersistentMessages(): Promise<INetworkResponse<any>> {
    try {
      // const { data } = await axios.get(`${API_URL}/channel/${channelId}`);
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
      const { data, error } = await supabase
        .from('channel')
        .select(
          `
          id,events,resource_id,channelId,
          resources (
            name
          )
        `
        )
        .eq('account_id', this.accountId());

      const response = data.map((row) => {
        const groupedData = {};
        row.events.forEach((event) => {
          const eventType = event.event;
          const eventData =
            typeof event.data === 'string'
              ? JSON.parse(event.data)
              : event.data;

          if (!groupedData[eventType]) {
            groupedData[eventType] = [];
          }

          groupedData[eventType].push({
            data: eventData,
            name: event.name,
            channel: event.channel,
            event: event.event,
          });
        });
        row.events = groupedData;
        return { ...row, tableId: row.resource_id };
      });

      return {
        success: true,
        payload: response || [],
      };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unhandled error getting persistent messages';
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  static pusherAuthorizer(
    channel: PusherTypes.Channel,
    restaurantId: string,
    tableId: string
  ) {
    return {
      authorize: (socketId: string, callback: any) => {
        fetch(`${window._env_.API_URL}/pusher/auth`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({
            socketId: socketId,
            channelId: channel.name,
            restaurantId: restaurantId,
            tableId: tableId,
          }),
        })
          .then((res: any) => {
            if (!res.ok) {
              throw new Error(`Received ${res.statusCode} from `);
            }

            return res.json();
          })
          .then((data) => {
            callback(null, data);
          })
          .catch((err) => {
            console.error('ERR', err);

            callback(new Error(`Error calling auth endpoint: ${err}`), {
              auth: '',
            });
          });
      },
    };
  }

  static async getMapperId(params: {
    restaurantUUID: string;
    tableUUID: string;
  }): Promise<{
    tableId: string;
    restaurantId: string;
  }> {
    const { restaurantUUID, tableUUID } = params;
    const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
    const accountResponse = await supabase
      .from('accounts')
      .select('id')
      .eq('uuid', restaurantUUID)
      .limit(1)
      .single();

    const resourceResponse = await supabase
      .from('resources')
      .select('id')
      .eq('uuid', tableUUID)
      .limit(1)
      .single();

    return {
      tableId: resourceResponse.data.id,
      restaurantId: accountResponse.data.id,
    };
  }
  static async clientHandshake(
    tableId: string | undefined,
    dynamicCode: string | undefined,
    restaurantId: string | undefined
  ): Promise<any> {
    try {
      const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);

      const { data, error } = await supabase
        .from('channel')
        .select(
          'channelId, events, account_id, resource_id, resources!inner(uuid), accounts!inner(uuid)'
        )
        .eq('resources.dynamicCode', dynamicCode)
        .eq('resources.uuid', tableId)
        .eq('accounts.uuid', restaurantId)
        .order('id', { ascending: false })
        .limit(1)
        .single();

      const payload = {
        channelId: data.channelId,
        tableId: data.resource_id,
        restaurantId: data.account_id,
        events: data.events,
      };
      return { success: true, payload };
    } catch (error: any) {
      const errorMessage =
        error.message || 'Unhandled error regenerating QR code';
      console.error(error);
      return { success: false, message: errorMessage };
    }
  }
}
