import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import km from './locales/kh.json';
import  cn from './locales/cn.json';
const resources = {
  en: { translation: en },
  km: { translation: km },
  'zh-CN': { translation: cn },
} as const; 

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
  export type TranslationKeys = keyof typeof resources['en']['translation'];
  export default i18n;