import React from 'react';

// Icons
import { QrCodeScanner, GlobeIcone, BackArrowIcon } from '../../Assets/index';

// Types
import {
  EScreenMode,
  LanguageSetting,
} from '../../Interfaces/generalInterfaces';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface IHeaderNav {
  screenMode: EScreenMode;
  title?: string;
  QRAction?: () => void;
  LangAction?: () => void;
  setScreenMode?: (screenMode: EScreenMode) => void;
  language?: LanguageSetting;
  backURL?: string;
  onDismiss?: () => void;
}

export const HeaderNav: React.FC<IHeaderNav> = ({
  title,
  QRAction,
  LangAction,
  screenMode,
  setScreenMode,
  language,
  backURL,
  onDismiss,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();

  let template: JSX.Element;
  if (screenMode === EScreenMode.HOME) {
    template = (
      <>
        <div className="flex  justify-between">
          <div className="flex space-x-2 text-center align-middle">
            <div
              onClick={QRAction}
              className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <QrCodeScanner className="h-6 w-6 swap-button" />
              <QrCodeScanner className="h-6 w-6 swap-button-active" />
            </div>
            <h2 className="text-left text-xl font-bold leading-normal">
              {title}
            </h2>
          </div>
          <div className="flex space-x-1" onClick={LangAction}>
            <GlobeIcone className="h-7 w-7" />
            <p>{language}</p>
          </div>
        </div>
      </>
    );
  } else {
    template = (
      <div className="flex space-x-2 text-center align-middle items-center">
        <div className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2">
          {screenMode === EScreenMode.BACK && (
            <Link to={backURL}>
              <BackArrowIcon className="h-8 w-8" />
            </Link>
          )}
          {screenMode === EScreenMode.DISMISS && (
            <BackArrowIcon onClick={onDismiss} className="h-8 w-8" />
          )}
        </div>
        <h2 className="text-left text-xl font-bold  align-middle">{ t(title)}</h2>
      </div>
    );
  }

  return (
    <div className="bg-7m-bg-main m-auto w-full  px-4 py-4 sm:px-4 lg:px-4 sticky top-0 text-white items-center z-10">
      {template}
    </div>
  );
};
