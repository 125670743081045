import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { InputNumber } from './InputNumber';
import { ICartItem, IProduct } from '../../Interfaces/generalInterfaces';
import { formatPrice } from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
export interface FoodItemDetialProp {
  product: IProduct;
  onAddToCart: (cart: ICartItem) => void;
}
export const FoodItemDetail: React.FC<FoodItemDetialProp> = ({
  product,
  onAddToCart,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();

  const [value, setValue] = useState<number>(1);
  const [remark, setRemark] = useState<string>('');

  const handleDecrease = () => {
    if (value <= 0) {
      return;
    } else {
      setValue(value - 1);
    }
  };

  const handleIncrease = () => {
    setValue(value + 1);
  };
  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
  };
  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
  };
  const handeAddToCart = () => {
    if (value === 0) return;
    return onAddToCart({
      id: uuidv4(),
      product: product,
      value: value,
      remark: remark,
    });
  };
  return (
    <div className="mb-3">
      <img
        className="w-full shadow-md shadow-slate-400"
        src={product.image_url || '/images/food-4.png'}
        alt="sample"
      ></img>
      <div className="px-5 py-1">
        <h1 className="text-lg font-bold">{product.name}</h1>
        <p className="text-xs font-light">{product.description}</p>
        <h2 className="text-lg font-bold">{formatPrice(product.price)}</h2>
      </div>

      {/* <div className=" bg-7m-bg-gray-primary px-5 py-2"> */}
      {/* <h2 className="text-md">Please choose size</h2> */}
      {/* <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Large"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="Large"
            control={<Radio />}
            label="Large"
          />
          <FormControlLabel
            value="Medium"
            control={<Radio />}
            label="Medium"
          />
          
        </RadioGroup>
      </FormControl> */}
      {/* </div> */}
      <div className="px-5 py-1">
        <TextField
          className="w-full"
          id="outlined-basic"
          label={t('Remark')}
          value={remark}
          variant="outlined"
         
          onChange={(e) => handleRemarkChange(e)}
        />   
      </div>
      <div className="flex items-center justify-between px-5 py-2">
        <InputNumber
          value={value}
          onDecrease={handleDecrease}
          onIncrease={handleIncrease}
          onValueChange={(e) => handleChange(e)}
        />
        <div
          className={`${
            value > 0 ? 'bg-7m-bg-main' : 'bg-slate-300'
          } px-4 py-2 rounded-xl text-white font-bold`}
          onClick={() => handeAddToCart()}
        >
          {t('Add to Cart')}
        </div>
      </div>
    </div>
  );
};
