import React, { useEffect, useRef, useState } from 'react';
import { ICategory, IProduct } from '../../Interfaces/generalInterfaces';
import { formatPrice } from '../../utils';
import _, { forEach } from 'lodash';
import { useTranslation } from 'react-i18next';
export interface ProductContentProps {
  products: Array<IProduct>;
  categories: Array<ICategory>;
  onSelectedItem?: (item: IProduct) => void;
  onScroll?: () => void;
}
export const FoodList: React.FC<ProductContentProps> = ({
  products,
  categories,
  onSelectedItem,
  onScroll,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState('All');

  const categoryRefs = {};
  // Intersection observer callback function
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setSelectedCategory(entry.target.dataset.category);
      }
    });
  };

  useEffect(() => {
    if (selectedCategory != null) {
      categoryRefs[selectedCategory]?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);
  forEach(categories, function (v) {
    categoryRefs[v.id] = React.createRef();
  });

  
  return (
    <>
      {categories && categories.map((category) => (
        
        <div
          className="mt-4"
          id={category.name}
          key={category.id}
          ref={categoryRefs[category.id]}
        >
          <div className="flex mx-5 items-center">
            <h1 className="text-2xl font-bold">{ t(category.name) }</h1>
          </div>
          {products && products
            .filter((product) =>
              product.categories.some((cat) => cat.id === category.id)
            )
            .map((product) => (
              <div
                key={product.id}
                onClick={() => onSelectedItem(product)}
                className="p-5 border-b border-x-7m-bg-gray-primary"
              >
                <div className="flex items-center justify-between ">
                  <div className="text-left">
                    <p>{product.name}</p>
                    <p>{formatPrice(product.price)}</p>
                  </div>
                  <div>
                    <img
                      className="w-24 shadow-md shadow-slate-400"
                      src={product.image_url || '/images/food-4.png'}
                      alt={product.name}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      ))}
    </>
  );
};
